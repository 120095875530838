import { useMemo } from 'react';
import './App.css';

function App() {
  const total = 30;
  const countPerRow = 3;

  const list = useMemo(() => {
    const set = new Set();
    let count = 0;
    const res = [];

    const generate = () => {
      const isAdd = Math.random() > 0.5;
      const first = Math.floor(Math.random() * 80) + (isAdd ? 11 : 21);
      const second = Math.floor(Math.random() * (isAdd ? (100 - first) : (first - 10))) + 1;
      const ans = isAdd ? first + second : first - second;
      return `${first} ${isAdd ? '+' : '-'} ${second} = ${ans}`;
    };

    for (let i = 0, len = Math.ceil(total / countPerRow); i < len; i++) {
      const row = [];
      for (let j = 0; j < countPerRow && count <= total; j++) {
        let cur = generate();
        if (set.has(cur)) {
          cur = generate();
        }
        set.add(cur);
        row.push(cur);
        count++;
      }
      res.push(row);
    }

    return res;
  }, []);

  return (
    <div className="App">
      <table className='list'>
        <caption className='title'>2位数加减法口算</caption>
        <tbody>
          {list.map((row, index) => (
            <tr key={index}>
              {row.map((item, subIndex) => (
                <td key={subIndex}>{item}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
